import { mq } from '../../styles/mediaqueries';
import { brandBlue } from '../../styles/colors';

export const iconListStyle = {
    margin: '5px 0 -23px',
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    listStyle: 'none',
    color: brandBlue,

    [mq[1]]: {
        justifyContent: 'flex-start',
    },
    [mq[2]]: {
        position: 'absolute',
        bottom: 'auto',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        margin: 0,
    },
    [mq[3]]: {
        top: 20,
    },
};

export const iconListItemtyle = {
    padding: 15,
    fontFamily: 'Open Sans Condensed',
    whiteSpace: 'nowrap',

    [mq[3]]: {
        padding: '15px 30px',
    },
};

export const iconListIconStyle = {
    marginBottom: 15,
    position: 'relative',

    svg: {
        width: 30,
        height: 30,
        marginTop: 5,
        margin: '0 auto',
        display: 'block',
        fill: brandBlue,

        [mq[1]]: {
            width: 50,
            height: 50,
        },
    },
};
