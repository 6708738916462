import { brandGreen, brandBlue } from '../../../styles/colors';
import { mq } from '../../../styles/mediaqueries';

export const getAnswerChoiceStyle = ({ isValid, isNotValid, isDisabled }) => {
    let backgroundColor = 'transparent';
    let borderColor = brandBlue;

    if (isValid) {
        backgroundColor = brandGreen;
        borderColor = brandGreen;
    }

    if (isNotValid) {
        backgroundColor = brandBlue;
        borderColor = brandBlue;
    }

    return {
        border: `1px solid ${borderColor}`,
        padding: '14px 20px',
        width: '100%',
        fontSize: 12,
        fontWeight: 700,
        letterSpacing: '0.6',
        display: 'flex',
        outline: 'none',
        backgroundColor,
        margin: '15px 0',
        textAlign: 'left',
        borderRadius: '5px',
        alignItems: 'center',
        transition: 'all 0.5s',
        cursor: isDisabled ? 'default' : 'pointer',
        pointerEvents: isDisabled ? 'none' : 'auto',
        color: isValid || isNotValid ? 'white' : brandBlue,

        '&:hover, &:focus': {
            color: 'white',
            backgroundColor:
                isValid || isNotValid ? backgroundColor : brandBlue,
        },

        [mq[1]]: {
            maxWidth: 320,
            minHeight: 66,
        },
    };
};

export const answerChoiceKeyStyle = {
    marginRight: 15,
    lineHeight: '1em',
};

export const answerChoiceValidStyle = {
    svg: {
        width: 15,
        height: 15,
        minWidth: 15,
        fill: 'white',
        marginRight: 15,
    },
};

export const answerChoiceNotValidStyle = {
    svg: {
        width: 12,
        height: 12,
        minWidth: 15,
        fill: 'white',
        marginRight: 15,
    },
};
