import { mq, mqMinHeight } from '../../../styles/mediaqueries';
import { headerHeight } from '../../header/styles';

import { maxWidthContent } from '../../layout/styles';

export const getPageStyle = ({ backgroundColor }) => ({
    width: '100%',
    margin: 'auto',
    backgroundColor,
    display: 'flex',
    padding: '20px 0',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'flex-start',

    [mq[1]]: {
        alignItems: 'center',
        paddingTop: headerHeight,
        paddingBottom: 0,
        minHeight: `calc(100vh - ${headerHeight}px)`,
        overflow: 'hidden',
    },

    [mqMinHeight]: {
        height: 'auto',
    },

    '&.hero': {
        '> div': {
            'div:nth-of-type(1)': {
                order: 1,
                [mq[1]]: {
                    order: 0,
                },
            },
            'div:nth-of-type(2)': {
                [mq[1]]: {
                    img: {
                        margin: '0 0 0 auto',
                    },
                },
            },
        },
    },
});

export const pageContentStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: maxWidthContent,

    [mq[1]]: {
        width: '88%',
        flexDirection: 'row',
    },
};

export const stickyBtnWrapper = {
    backgroundColor: 'white',
    padding: '22px 15px',
    boxSizing: 'border-box',
    width: '100%',
    zIndex: 10,
    position: 'fixed',
    left: 0,
    bottom: 0,
    textAlign: 'center',

    button: {
        width: '100%',
    },

    [mq[2]]: {
        display: 'none',
    },
};

export const btnWrapper = {
    display: 'none',
    [mq[2]]: {
        display: 'block',
        marginTop: 30,
    },
};
