import React from 'react';
import { node } from 'prop-types';
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';

import globalStyle from '../../styles/global';
import { layoutStyle, layoutContentStyle } from './styles';

import SEO from '../seo';
import Footer from '../footer/index';

const Layout = ({ children }) => (
    <div css={layoutStyle}>
        <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Poppins:wght@400;500;700&display=swap"
                rel="stylesheet"
            />
        </Helmet>

        <Global styles={globalStyle} />

        <SEO title="Join Candriam Academy" />

        <main css={layoutContentStyle}>{children}</main>

        <Footer />
    </div>
);

Layout.propTypes = {
    children: node.isRequired,
};

export default Layout;
