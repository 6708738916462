import React from 'react';
import styled from '@emotion/styled';
import { node, string } from 'prop-types';

import pageContentContainerStyle from './styles';

const StyledPageContentContainer = styled.div(pageContentContainerStyle);

const PageContentContainer = ({ children, align }) => (
    <StyledPageContentContainer align={align}>
        {children}
    </StyledPageContentContainer>
);

PageContentContainer.propTypes = {
    children: node.isRequired,
    align: string,
};

PageContentContainer.defaultProps = {
    align: null,
};

export default PageContentContainer;
