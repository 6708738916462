import React from 'react';
import { string } from 'prop-types';

import ScrollToBottomIcon from '../../icons/scrollToBottom';

import scrollToBottomStyle from './styles';

const ScrollToBottom = ({ label }) => (
    <div css={scrollToBottomStyle}>
        {label}
        <ScrollToBottomIcon />
    </div>
);

ScrollToBottom.propTypes = {
    label: string.isRequired,
};

export default ScrollToBottom;
