import { mq } from '../../styles/mediaqueries';
import { brandGreen, brandBlue, greyDark } from '../../styles/colors';

export const quizStyle = {
    width: '100%',
};

export const getQuizQuestionStyle = ({ isVisible }) => ({
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: isVisible ? 'flex' : 'none',

    [mq[1]]: {
        flexDirection: 'row',
    },
});

export const quizQuestionContentStyle = {
    width: '100%',

    [mq[1]]: {
        width: 400,
    },

    [mq[2]]: {
        width: 450,
    },

    [mq[3]]: {
        width: '50%',
    },
};

export const quizQuestionVisuStyle = {
    width: '100%',

    [mq[1]]: {
        width: '50%',
        position: 'relative',
    },

    img: {
        display: 'none',
        maxWidth: 350,
        maxHeight: 250,
        margin: 'auto',

        [mq[1]]: {
            display: 'block',
            maxWidth: '100%',
            maxHeight: 'none',
        },
        [mq[2]]: {
            marginRight: 0,
        },
        [mq[3]]: {
            marginRight: 'auto',
        },
    },
};

export const getQuizQuestionResultStyle = ({ isValid }) => ({
    padding: 15,
    margin: 'auto',
    color: 'white',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 30px)',
    backgroundColor: isValid ? brandGreen : brandBlue,

    [mq[1]]: {
        width: 125,
        height: 125,
        padding: 30,
        borderRadius: '50%',
        position: 'absolute',
        bottom: 4,
        '&.position-1, &.position-3': {
            left: 5,
        },
        '&.position-2': {
            left: 107,
        },
    },

    [mq[2]]: {
        width: 210,
        height: 210,
        '&.position-1': {
            left: 8,
        },
        '&.position-2': {
            left: 155,
        },
        '&.position-3': {
            bottom: 5,
        },
    },

    [mq[3]]: {
        width: 270,
        height: 270,
        bottom: 2,
        '&.position-1': {
            left: 47,
        },
        '&.position-2': {
            left: 238,
        },
        '&.position-3': {
            left: 52,
        },
    },

    svg: {
        width: 20,
        height: 20,
        fill: 'white',
        [mq[2]]: {
            width: 30,
            height: 30,
            marginBottom: 15,
        },
    },

    p: {
        color: 'white',
        maxWidth: '100%',
        fontSize: 14,
        lineHeight: 1.5,

        [mq[2]]: {
            fontSize: 12,
        },

        [mq[3]]: {
            fontSize: 14,
        },
    },

    strong: {
        fontSize: 25,
        display: 'block',
        paddingBottom: 15,
        textTransform: 'uppercase',

        [mq[1]]: {
            fontSize: 18,
            paddingBottom: 0,
        },

        [mq[2]]: {
            fontSize: 30,
        },

        [mq[3]]: {
            fontSize: 40,
        },
    },

    button: {
        [mq[1]]: {
            fontSize: '11px !important',
            paddingLeft: '12px !important',
            paddingRight: '12px !important',
        },
        [mq[2]]: {
            fontSize: '13px !important',
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
        },
    },
});

export const lastQuestionTextStyle = {
    [mq[1]]: {
        position: 'absolute',
        bottom: -130,
        left: 0,
        color: `${greyDark} !important`,
        width: 300,
        maxWidth: '150% !important',

        '&::before': {
            content: '"*"',
        },
    },
    [mq[2]]: {
        position: 'static',
        bottom: 'auto',
        color: 'white !important',
        width: 'auto',
        maxWidth: '100% !important',

        '&::before': {
            content: '""',
        },
    },
};
