import { mq } from '../../styles/mediaqueries';

import { headerNavMobileHeight } from '../header/styles';

const pageScrollerStyle = {
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    paddingTop: headerNavMobileHeight,
    overflowX: 'hidden',

    [mq[1]]: {
        paddingTop: 0,
    },
};

export default pageScrollerStyle;
