import React from 'react';
import { func, number } from 'prop-types';

import PageIndicatorButton from '../indicatorButton/index';

import indicatorStyle from './styles';

const Indicator = ({ goToPage, pageCount, activePage }) => {
    const indicators = Array.from(Array(pageCount).keys());

    return (
        <div css={indicatorStyle}>
            {indicators.map((indicator) => (
                <PageIndicatorButton
                    key={indicator}
                    pageIndex={indicator}
                    active={indicator === activePage}
                    goToPage={goToPage}
                />
            ))}
        </div>
    );
};

Indicator.propTypes = {
    goToPage: func.isRequired,
    pageCount: number.isRequired,
    activePage: number.isRequired,
};

export default Indicator;
