import React from 'react';
import styled from '@emotion/styled';
import { bool, string, func } from 'prop-types';

import ValidIcon from '../../../icons/valid';
import InvalidIcon from '../../../icons/invalid';

import {
    getAnswerChoiceStyle,
    answerChoiceValidStyle,
    answerChoiceNotValidStyle,
    answerChoiceKeyStyle,
} from './styles';

const StyledAnswerChoice = styled.button(getAnswerChoiceStyle);

const AnswerChoice = ({
    questionId,
    labelKey,
    labelValue,
    isValid,
    isNotValid,
    isDisabled,
    onClick,
}) => (
    <StyledAnswerChoice
        type="submit"
        isValid={isValid}
        isNotValid={isNotValid}
        isDisabled={isDisabled}
        onClick={() => onClick(questionId, labelKey)}
    >
        {!isValid && !isNotValid && (
            <span css={answerChoiceKeyStyle}>{labelKey}</span>
        )}

        {isNotValid && (
            <div css={answerChoiceNotValidStyle}>
                <InvalidIcon />
            </div>
        )}

        {isValid && (
            <div css={answerChoiceValidStyle}>
                <ValidIcon />
            </div>
        )}

        <span>{labelValue}</span>
    </StyledAnswerChoice>
);

AnswerChoice.propTypes = {
    questionId: string.isRequired,
    labelKey: string.isRequired,
    labelValue: string.isRequired,
    onClick: func.isRequired,
    isValid: bool,
    isNotValid: bool,
    isDisabled: bool,
};

AnswerChoice.defaultProps = {
    isValid: false,
    isNotValid: false,
    isDisabled: false,
};

export default AnswerChoice;
