import React from 'react';

const YoutubeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <path
            fill="#fff"
            d="M24.2,7.53a3.59,3.59,0,0,0-3.41-3.75c-2.56-.12-5.17-.16-7.83-.16h-.82c-2.66,0-5.27,0-7.83.16A3.59,3.59,0,0,0,.9,7.54C.78,9.18.74,10.82.74,12.47s0,3.28.15,4.93A3.6,3.6,0,0,0,4.3,21.16q4,.18,8.24.17c2.8,0,5.55,0,8.24-.17A3.6,3.6,0,0,0,24.2,17.4c.11-1.65.16-3.29.15-4.94S24.31,9.18,24.2,7.53ZM10.29,17V7.93L17,12.46Z"
        />
    </svg>
);

export default YoutubeIcon;
