import React from 'react';

const ScrollToBottomIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="50px" viewBox="0 0 22 50">
        <path d="M12.83,43.67l6.05-6.14c0.33-0.37,0.8-0.58,1.29-0.56c0.49-0.02,0.96,0.19,1.29,0.56c0.71,0.69,0.74,1.83,0.06,2.55c-0.02,0.02-0.04,0.04-0.06,0.06l-9.16,9.31C11.95,49.8,11.49,50,11,50c-0.49,0-0.95-0.2-1.29-0.56l-9.16-9.31c-0.71-0.69-0.74-1.83-0.06-2.55c0.02-0.02,0.04-0.04,0.06-0.06c0.33-0.37,0.8-0.58,1.29-0.56c0.49-0.01,0.96,0.19,1.28,0.56l6.05,6.14V1.87C9.11,0.9,9.84,0.06,10.79,0c0.07,0,0.14,0,0.21,0c0.96-0.06,1.78,0.68,1.83,1.65c0,0.07,0,0.14,0,0.21L12.83,43.67z" />
    </svg>

);

export default ScrollToBottomIcon;
