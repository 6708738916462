import { mq } from '../../styles/mediaqueries';
import { brandBlueLight, brandBlue } from '../../styles/colors';

const getButtonStyle = ({ isSmall, isInverse }) => {
    let color = brandBlueLight;

    if (isInverse) {
        color = 'transparent';
    }

    return {
        fontSize: 14,
        color: 'white',
        fontWeight: 700,
        borderRadius: 22,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        backgroundColor: color,
        textDecoration: 'none',
        display: 'inline-block',
        transition: 'all .3s ease-out',
        padding: isSmall ? '5px 25px' : '10px 25px',
        border: isInverse ? '1px solid white' : `1px solid ${color}`,

        [mq[2]]: {
            padding: isSmall ? '11px 25px' : '11px 25px',
        },

        [mq[3]]: {
            paddingLeft: 35,
            paddingRight: 35,
        },

        '&:hover, &:focus': {
            backgroundColor: brandBlue,
        },
    };
};

export default getButtonStyle;
