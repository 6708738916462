import { mq } from '../../../styles/mediaqueries';

const pageVisuStyle = ({ size }) => {
    let width = '50%';

    if (size) {
        if (size === 'fullwidth') {
            width = '100%';
        }
    }

    return {
        padding: 20,
        position: 'relative',

        [mq[1]]: {
            padding: '30px 0',
            width,
        },

        [mq[2]]: {
            '&:nth-child(odd)': {
                img: {
                    marginLeft: 0,
                },
            },
        },

        [mq[3]]: {
            '&:nth-child(odd)': {
                img: {
                    marginLeft: 'auto',
                },
            },
        },

        img: {
            maxWidth: '80%',
            maxHeight: 'none',
            margin: 'auto',
            display: 'block',

            [mq[1]]: {
                margin: '0 auto 0 0',
            },

            [mq[3]]: {
                maxWidth: '100%',
            },
        },

        '.greenies': {
            width: '15vw',
            position: 'absolute',
            left: 30,
            bottom: 90,

            [mq[1]]: {
                width: '10vw',
                left: -30,
            },

            [mq[2]]: {
                width: 80,
                left: -40,
                bottom: 40,
            },

            [mq[3]]: {
                width: 'auto',
                left: -10,
                bottom: 30,
            },
        },
    };
};

export default pageVisuStyle;
