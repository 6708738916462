import { brandBlue } from '../../../styles/colors';

const getIndicatorButtonStyle = ({ active }) => ({
    width: 8,
    border: 0,
    padding: 0,
    margin: 10,
    outline: 'none',
    borderRadius: 4,
    height: active ? 25 : 8,
    backgroundColor: brandBlue,
    transition: 'height 0.5s ease',
    cursor: active ? 'default' : 'pointer',
});

export default getIndicatorButtonStyle;
