import React, { Component } from 'react';
import { string } from 'prop-types';

import Button from '../button/index';
import ToggleNavButton from './toggleNavButton';
import { PageScrollerContext } from '../pageScroller/index';

import { breakpoints } from '../../styles/mediaqueries';

import logo from '../../images/candriam-academy.svg';

import {
    headerStyle,
    headerLogoStyle,
    headerNavStyle,
    headerNavItemStyle,
    headerLinkStyle,
} from './styles';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTopBarDisplayed: false,
            isNavMobile: true,
        };
    }

    componentDidMount = () => {
        this.navDisplay();
        window.addEventListener('resize', this.navDisplay);
    };

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.navDisplay);
    };

    navDisplay = () => {
        this.setState({
            isNavMobile: window.innerWidth <= breakpoints[1],
        });
    };

    toggleNav = () => {
        this.setState(({ isTopBarDisplayed }) => ({
            isTopBarDisplayed: !isTopBarDisplayed,
        }));
    };

    render() {
        const { siteTitle } = this.props;
        const { isTopBarDisplayed, isNavMobile } = this.state;

        return (
            <header css={headerStyle}>
                <img src={logo} alt={siteTitle} css={headerLogoStyle} />

                {isNavMobile && (
                    <ToggleNavButton
                        onClick={this.toggleNav}
                        isOpen={isTopBarDisplayed}
                    />
                )}

                {(isTopBarDisplayed || !isNavMobile) && (
                    <ul css={headerNavStyle}>
                        {!isNavMobile && (
                            <li css={headerNavItemStyle}>
                                <PageScrollerContext.Consumer>
                                    {({ goToPage }) => (
                                        <Button
                                            label="Register for training"
                                            isSmall
                                            onClick={() => goToPage(3)}
                                        />
                                    )}
                                </PageScrollerContext.Consumer>
                            </li>
                        )}
                        <li css={headerNavItemStyle}>
                            <a
                                href="https://academy.candriam.com/about/"
                                target="_blank"
                                rel="noopener noreferrer"
                                css={headerLinkStyle}
                            >
                                About
                            </a>
                        </li>
                        <li css={headerNavItemStyle}>
                            <a
                                href="https://academy.candriam.com/contact/"
                                target="_blank"
                                rel="noopener noreferrer"
                                css={headerLinkStyle}
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                )}
            </header>
        );
    }
}

Header.propTypes = {
    siteTitle: string.isRequired,
};

export default Header;
