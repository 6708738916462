import React from 'react';
import styled from '@emotion/styled';
import { string, func, bool } from 'prop-types';

import getButtonStyle from './styles';

const StyledButton = styled.button(getButtonStyle);
const StyledLink = styled.a(getButtonStyle);

const Button = ({
    label, isSmall, isInverse, isSecondary, href, onClick,
}) => {
    if (href) {
        return (
            <StyledLink
                href={href}
                target="_blank"
                isSmall={isSmall}
                isInverse={isInverse}
                isSecondary={isSecondary}
            >
                {label}
            </StyledLink>
        );
    }

    return (
        <StyledButton
            type="button"
            onClick={onClick}
            isSmall={isSmall}
            isInverse={isInverse}
            isSecondary={isSecondary}
        >
            {label}
        </StyledButton>
    );
};

Button.propTypes = {
    label: string.isRequired,
    href: string,
    onClick: func,
    isSmall: bool,
    isInverse: bool,
    isSecondary: bool,
};

Button.defaultProps = {
    href: null,
    onClick: null,
    isSmall: false,
    isInverse: false,
    isSecondary: false,
};

export default Button;
