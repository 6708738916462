import { mq } from '../../styles/mediaqueries';
import { brandBlue, greyDark, greyLight } from '../../styles/colors';

export const headerHeight = 80;
export const headerNavMobileHeight = 70;

export const headerStyle = {
    top: 0,
    left: 0,
    zIndex: 10,
    padding: 20,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'fixed',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    boxShadow: '0 0 6px rgba(0, 0, 0, 0.1)',

    [mq[1]]: {
        padding: '20px 30px',
    },
};

export const headerLogoStyle = {
    maxWidth: 220,
    marginTop: 'auto',
    alignSelf: 'center',
    marginBottom: 'auto',

    [mq[1]]: {
        maxWidth: 270,
    },
};

export const headerNavStyle = {
    margin: 0,
    padding: 0,
    width: '100%',
    marginTop: 15,
    paddingTop: 10,
    display: 'flex',
    listStyle: 'none',
    flexDirection: 'column',
    borderTop: `1px solid ${greyLight}`,

    [mq[1]]: {
        marginTop: 0,
        width: 'auto',
        paddingTop: 0,
        borderTop: 'none',
        flexDirection: 'row',
        alignItems: 'center',
    },
};

export const headerNavItemStyle = {
    padding: '5px 0',

    [mq[1]]: {
        padding: '0 15px',
    },
};

export const headerLinkStyle = {
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 1,
    textDecoration: 'none',
    color: greyDark,
    textTransform: 'uppercase',
    transition: 'all .3s ease-out',

    '&:hover, &:focus': {
        color: brandBlue,
    },
};
