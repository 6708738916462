import React from 'react';

const TwitterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <path
            fill="#fff"
            d="M22.65,6.11a8.54,8.54,0,0,1-2.42.67,4.23,4.23,0,0,0,1.85-2.33,8.58,8.58,0,0,1-2.67,1,4.21,4.21,0,0,0-7.17,3.84,11.92,11.92,0,0,1-8.67-4.4,4.21,4.21,0,0,0,1.3,5.62A4.12,4.12,0,0,1,3,10v.06a4.21,4.21,0,0,0,3.38,4.12,4.21,4.21,0,0,1-1.9.07,4.22,4.22,0,0,0,3.93,2.93A8.56,8.56,0,0,1,3.14,19a8.39,8.39,0,0,1-1-.06A12,12,0,0,0,20.56,9V8.84c0-.19,0-.37,0-.55a8.54,8.54,0,0,0,2.1-2.18Z"
        />
    </svg>
);

export default TwitterIcon;
