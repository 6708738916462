import React from 'react';

import logo from '../../images/candriam.png';
import partnerInvestopedia from '../../images/partners/partner-investopedia.jpg';
import partnerResponsibleInvestor from '../../images/partners/partner-responsible_investor.jpg';
import partnerEurosif from '../../images/partners/partner-eurosif.jpg';
import partnerUksif from '../../images/partners/partner-uksif.jpg';
import partnerAipb from '../../images/partners/partner-aipb.jpg';
import partnerSustainableInvestment from '../../images/partners/partner-sustainable_investment.jpg';
import partnerFinPix from '../../images/partners/partner-fin_pix.jpg';
import partnerPld from '../../images/partners/partner-pld.jpg';

import TwitterIcon from '../../icons/twitter';
import LinkedinIcon from '../../icons/linkedin';
import YoutubeIcon from '../../icons/youtube';

import Button from '../button/index';
import {
    footerStyle,
    footerPartnersStyle,
    footerPartnersListStyle,
    footerPartnersContentStyle,
    footerMainStyle,
    footerMainContentStyle,
    footerSocialList,
    footerMainAboutStyle,
    footerMainAboutTitleStyle,
    footerMainAboutContentStyle,
    footerMainAboutLogoStyle,
} from './styles';

const Footer = () => (
    <footer css={footerStyle}>
        <div css={footerPartnersStyle}>
            <div css={footerPartnersContentStyle}>
                Our&nbsp;partners&nbsp;:
                <ul css={footerPartnersListStyle}>
                    <li>
                        <img src={partnerInvestopedia} alt="Investopedia" />
                    </li>
                    <li>
                        <img
                            src={partnerResponsibleInvestor}
                            alt="Responsible investor"
                        />
                    </li>
                    <li>
                        <img src={partnerEurosif} alt="Eurosif" />
                    </li>
                    <li>
                        <img
                            src={partnerUksif}
                            alt="UK Sustainable Investment and Finance Association"
                        />
                    </li>
                    <li>
                        <img
                            src={partnerAipb}
                            alt="Associazione Italiana Private Banking"
                        />
                    </li>
                    <li>
                        <img
                            src={partnerSustainableInvestment}
                            alt="Sustainable Investement Training Course"
                        />
                    </li>
                    <li>
                        <img src={partnerFinPix} alt="Fin Pix" />
                    </li>
                    <li>
                        <img src={partnerPld} alt="PLD" />
                    </li>
                </ul>
            </div>
        </div>

        <div css={footerMainStyle}>
            <div css={footerMainContentStyle}>
                <div css={footerMainAboutStyle}>
                    <div>
                        <img
                            src={logo}
                            alt="Candriam - A New York life investements company"
                            css={footerMainAboutLogoStyle}
                        />
                        <ul css={footerSocialList}>
                            <li>
                                <a
                                    href="https://twitter.com/candriam"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="See twitter"
                                >
                                    <TwitterIcon />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/candriam"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="See twitter"
                                >
                                    <LinkedinIcon />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/channel/UC3PkKpu2f0gglb4TwOBILZA"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="See twitter"
                                >
                                    <YoutubeIcon />
                                </a>
                            </li>
                        </ul>
                        <Button
                            label="Visit our corporate website"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.candriam.com/"
                        />
                    </div>
                    <div css={footerMainAboutContentStyle}>
                        <strong css={footerMainAboutTitleStyle}>
                            About Candriam
                        </strong>
                        <p>
                            CANDRIAM has been offering innovative and
                            diversified investment solutions for more than 20
                            years in bonds, equities, absolute performance
                            strategies, asset allocation and – more recently –
                            real estate, having acquired a 40% equity interest
                            in UK-based specialist Tristan Capital Partners.
                        </p>
                        <p>
                            CANDRIAM is an affiliate of New York Life, a Fortune
                            100© company. With more than €590bn of assets under
                            management (AUM), New York Life Investments ranks
                            among the world&apos;s largest asset managers.
                        </p>
                        <p>
                            Our team of 600 professionals manages about €150
                            billion of AUM. We operate management offices in
                            Luxembourg, Brussels, Paris, and London, serving
                            clients in Europe, Asia-Pacific and Americas
                            covering more than 20 countries.
                        </p>

                        <p style={{ marginTop: 30, marginBottom: 0 }}>
                            © Candriam 2022 - All rights reserved &nbsp;|&nbsp;
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.candriam.com/en/professional/legal-and-disclaimer-candriam/legal-information/"
                            >
                                Legal Information
                            </a>
                            &nbsp;|&nbsp;
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.candriam.com/en/professional/contact/"
                            >
                                Contact Us
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
