import { mq } from '../../../styles/mediaqueries';
import { brandBlue } from '../../../styles/colors';

const pageContentStyle = ({ size }) => {
    let width = '50%';

    if (size) {
        if (size === 'fullwidth') {
            width = '100%';
        }
    }

    return {
        padding: 20,
        width: 'calc(100% - 40px)',

        [mq[1]]: {
            padding: '30px 0',
            width,
        },

        'h1 + p': {
            fontSize: 18,
            fontWeight: 700,
        },

        'h2 + strong': {
            fontSize: 18,
        },

        'h2, h4': {
            color: brandBlue,
        },
    };
};

export default pageContentStyle;
