import { brandBlue } from '../../../styles/colors';

const getHeaderToggleNavButtonStyle = ({ isOpen }) => ({
    border: 0,
    width: 30,
    height: 20,
    outline: 'none',
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: 'transparent',

    span: {
        left: 0,
        height: 4,
        opacity: 1,
        width: '100%',
        borderRadius: 5,
        display: 'block',
        position: 'absolute',
        background: brandBlue,
        transition: '.25s ease-in-out',
    },

    'span:nth-of-type(1)': {
        top: isOpen ? 8 : 0,
        width: isOpen && '0%',
        left: isOpen && '50%',

    },
    'span:nth-of-type(2)': {
        top: 8,
        transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
    },

    'span:nth-of-type(3)': {
        top: 8,
        transform: isOpen ? 'rotate(-45deg)' : 'rotate(0deg)',
    },

    'span:nth-of-type(4)': {
        top: isOpen ? 8 : 16,
        width: isOpen && '0%',
        left: isOpen && '50%',
    },
});

export default getHeaderToggleNavButtonStyle;
