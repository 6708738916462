import React from 'react';
import {
    arrayOf, shape, string, node,
} from 'prop-types';

import { iconListStyle, iconListItemtyle, iconListIconStyle } from './styles';

const IconList = ({ list }) => (
    <ul css={iconListStyle}>
        {list.map((listItem) => (
            <li key={listItem.label} css={iconListItemtyle}>
                <div css={iconListIconStyle}>
                    {listItem.icon}
                </div>
                {listItem.label}
            </li>
        ))}
    </ul>
);

IconList.propTypes = {
    list: arrayOf(shape({
        icon: node.isRequired,
        label: string.isRequired,
    })).isRequired,
};

export default IconList;
