import React from 'react';
import { number } from 'prop-types';

import indicatorStyle from './styles';

const Indicator = ({ currentQuestion, numberOfQuestions }) => (
    <div css={indicatorStyle}>
        Question&nbsp;
        {currentQuestion}
        /
        {numberOfQuestions}
    </div>
);

Indicator.propTypes = {
    currentQuestion: number.isRequired,
    numberOfQuestions: number.isRequired,
};

export default Indicator;
