export const brandBlue = '#0071BA';
export const brandBlueLight = '#24B8F1';
export const brandGreen = '#91BE61'; // feedback Question

export const greyLight = '#FAF8F5';
export const greyMedium = '#707070';
export const greyDark = '#323d3e';

export const brandBeigeLight = '#FAF8F5';
export const brandBeigeDark = '#F5F3ED';
