import { mq } from '../../styles/mediaqueries';
import { brandBlue } from '../../styles/colors';
import { maxWidthContent } from '../layout/styles';

export const footerStyle = {
    width: '100%',
    color: 'white',
    paddingBottom: 90, // sticky btn
    [mq[2]]: {
        paddingBottom: 0,
    },
};

export const footerPartnersStyle = {
    padding: '30px 15px 0',
    color: brandBlue,
    fontSize: 18,
    fontWeight: 700,
    textAlign: 'center',

    [mq[2]]: {
        padding: '15px 30px',
    },
};

export const footerPartnersContentStyle = {
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    maxWidth: maxWidthContent,

    [mq[2]]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
};

export const footerPartnersListStyle = {
    paddingLeft: 0,
    marginLeft: -5,
    marginRight: -5,
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
    justifyContent: 'space-around',

    [mq[2]]: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 30,
        flexWrap: 'no-wrap',
    },

    li: {
        margin: '5px 1%',
        width: '30%',

        [mq[1]]: {
            width: 140,
        },

        [mq[2]]: {
            width: 80,
        },

        [mq[3]]: {
            width: 100,
        },

        img: {
            maxWidth: '100%',
        },
    },
};

export const footerMainStyle = {
    padding: '30px 15px',
    backgroundColor: brandBlue,

    [mq[1]]: {
        padding: 30,
    },

    p: {
        fontSize: 12,
        fontWeight: 300,
        lineHeight: '20px',
        letterSpacing: '0.3px',
        color: 'white',
    },

    a: {
        color: 'white',
        textDecoration: 'none',
        '&:hover, &:focus': {
            textDecoration: 'underline',
            textUnderlineOffset: 1,
        },
    },

    sup: {
        padding: '0 5px',
    },
};

export const footerMainContentStyle = {
    margin: '0 auto',
    maxWidth: maxWidthContent,
};

export const footerSocialList = {
    listStyle: 'none',
    paddingLeft: 0,
    margin: '30px 0 25px',
    display: 'flex',

    [mq[2]]: {
        marginTop: 45,
    },

    li: {
        marginRight: 20,
    },

    a: {
        display: 'block',
        width: 25,
        height: 25,
    },

    svg: {
        width: '100%',
        height: '100%',
    },
};

export const footerMainAboutStyle = {
    [mq[1]]: {
        display: 'flex',
        alignItems: 'flex-start',
    },

    [mq[3]]: {
        alignItems: 'center',
    },
};

export const footerMainAboutContentStyle = {
    marginTop: 45,

    [mq[1]]: {
        marginTop: 0,
        paddingLeft: 80,
    },
};

export const footerMainAboutTitleStyle = {
    fontSize: 14,
};

export const footerMainAboutLogoStyle = {
    width: 220,
    maxWidth: 220,

    [mq[3]]: {
        width: 270,
        maxWidth: 270,
    },
};

export const footerMainDisclaimerStyle = {
    fontSize: '11px !important',
    lineHeight: '16px',
    letterSpacing: '0.5px !important',
    margin: '30px 0 20px',
};
