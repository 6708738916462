import { mq } from '../../../styles/mediaqueries';

const pageContentContainerStyle = ({ align }) => ({
    [mq[3]]: {
        width: '65%',
        paddingLeft: align === 'left' ? 0 : '17.5%',
    },

    'p:last-of-type': {
        marginBottom: 0,
    },
});

export default pageContentContainerStyle;
