import { greyMedium } from '../../../styles/colors';

const indicatorStyle = {
    color: greyMedium,
    fontWeight: 700,
    letterSpacing: 1,
    marginBottom: 2,
    fontSize: 14,
};

export default indicatorStyle;
