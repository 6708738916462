import React from 'react';
import styled from '@emotion/styled';
import { node, string } from 'prop-types';

import pageContentStyle from './styles';

const StyledPageContent = styled.div(pageContentStyle);

const PageContent = ({ children, size, animFrom }) => {
    if (animFrom) {
        return (
            <StyledPageContent
                size={size}
                data-sal={`slide-${animFrom}`}
                data-sal-duration="600"
            >
                {children}
            </StyledPageContent>
        );
    }
    return <StyledPageContent size={size}>{children}</StyledPageContent>;
};

PageContent.propTypes = {
    children: node.isRequired,
    size: string,
    animFrom: string,
};

PageContent.defaultProps = {
    size: null,
    animFrom: null,
};

export default PageContent;
