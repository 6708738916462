import React from 'react';
import styled from '@emotion/styled';
import { bool, func } from 'prop-types';

import getHeaderToggleNavButtonStyle from './styles';

const StyledHeaderToggleNavButton = styled.button(getHeaderToggleNavButtonStyle);

const ToggleNavButton = ({ onClick, isOpen }) => (
    <StyledHeaderToggleNavButton
        type="button"
        onClick={onClick}
        isOpen={isOpen}
        aria-label="Header - Toggle nav"
    >
        <span />
        <span />
        <span />
        <span />
    </StyledHeaderToggleNavButton>
);

ToggleNavButton.propTypes = {
    onClick: func.isRequired,
    isOpen: bool,
};

ToggleNavButton.defaultProps = {
    isOpen: false,
};

export default ToggleNavButton;
