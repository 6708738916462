import React, { Component } from 'react';
import { node } from 'prop-types';

import PageIndicator from './indicator/index';

import pageScrollerStyle from './styles';
import { breakpoints, breakpointsMinHeight } from '../../styles/mediaqueries';

export const PageScrollerContext = React.createContext();

class PageScroller extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pages: 0,
            scrollPos: 0,
            pageIndex: 0,
            isDisabled: null,
            pagesTopPosition: [],
        };

        this.pages = React.createRef();
    }

    componentDidMount = () => {
        this.getPages();
        this.pageScrollerDisplay();

        window.addEventListener('resize', this.pageScrollerDisplay);
    };

    componentDidUpdate = () => {
        const { isDisabled } = this.state;

        window.removeEventListener('scroll', this.handleScroll);

        if (!isDisabled) {
            window.addEventListener('scroll', this.handleScroll);
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.pageScrollerDisplay);
    };

    getPages = () => {
        const pages = [...this.pages.current.querySelectorAll('[data-page]')];
        const pagesTopPosition = [];

        pages.map((page) => {
            const pageTopPosition = page.offsetTop;
            pagesTopPosition.push(pageTopPosition);
        });

        this.setState({ pages: pages.length, pagesTopPosition });
    };

    setCurrentPage = () => {
        const { scrollPos, pagesTopPosition } = this.state;

        pagesTopPosition.map((pageTopPosition, index) => {
            if (scrollPos >= pageTopPosition) {
                this.setState({ pageIndex: index });
            }
        });
    };

    handleScroll = () => {
        this.setState({
            scrollPos: -document.body.getBoundingClientRect().top,
        });
        this.setCurrentPage();
    };

    scroll = (pageIndex) => {
        const { pagesTopPosition } = this.state;

        let padding = 0;
        const isMobile = window.innerWidth <= breakpoints[1];
        const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

        if (isMobile) {
            padding = 50;
        }

        if (isSmoothScrollSupported) {
            window.scrollTo({
                left: 0,
                top: pagesTopPosition[pageIndex] - padding,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo(0, pagesTopPosition[pageIndex] - padding);
        }
    };

    goToPage = async (pageIndex) => {
        await this.getPages();
        this.scroll(pageIndex);
    };

    pageScrollerDisplay = () => {
        this.setState({
            isDisabled:
                window.innerWidth <= breakpoints[1] || window.innerHeight <= breakpointsMinHeight,
        });
    };

    render() {
        const { children } = this.props;
        const { pageIndex, pages, isDisabled } = this.state;

        return (
            <div css={pageScrollerStyle} ref={this.pages}>
                {!isDisabled && (
                    <PageIndicator
                        pageCount={pages}
                        activePage={pageIndex}
                        goToPage={this.goToPage}
                    />
                )}
                <PageScrollerContext.Provider
                    value={{ goToPage: this.goToPage }}
                >
                    {children}
                </PageScrollerContext.Provider>
            </div>
        );
    }
}

PageScroller.propTypes = {
    children: node.isRequired,
};

export default PageScroller;
