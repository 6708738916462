import React, { Component } from 'react';
import styled from '@emotion/styled';
import { arrayOf, shape, string } from 'prop-types';

import Button from '../button';
import Indicator from './indicator/index';
import PageContentContainer from '../pageScroller/pageContentContainer/index';
import AnswerChoice from './answerChoice/index';

import ValidIcon from '../../icons/valid';
import InvalidIcon from '../../icons/invalid';

import {
    quizStyle,
    getQuizQuestionStyle,
    quizQuestionContentStyle,
    quizQuestionVisuStyle,
    getQuizQuestionResultStyle,
    lastQuestionTextStyle,
} from './styles';

import imgSlide3q1 from '../../images/slides/3q1.svg';
import imgSlide3q2 from '../../images/slides/3q2.svg';
import imgSlide3q3 from '../../images/slides/3q3.svg';

const imgSlide = [imgSlide3q1, imgSlide3q2, imgSlide3q3];
const StyledQuestion = styled.div(getQuizQuestionStyle);
const StyledQuestionResult = styled.div(getQuizQuestionResultStyle);

class Quiz extends Component {
    constructor(props) {
        super(props);

        const { quiz } = this.props;

        this.state = {
            quizResults: [],
            currentQuestion: 1,
            numberOfQuestions: quiz.length,
        };

        this.scrollLocker = () => {};
    }

    submitChoice = (questionId, choice) => {
        const { quizResults } = this.state;

        quizResults.push({
            id: questionId,
            selectedChoice: choice,
        });

        this.setState({
            quizResults,
        });
    };

    goToNextQuestion = () => {
        const { currentQuestion, numberOfQuestions } = this.state;

        if (currentQuestion < numberOfQuestions) {
            this.setState((prevstate) => ({
                currentQuestion: prevstate.currentQuestion + 1,
            }));
        }
    };

    render() {
        const {
            quiz,
            validQuestion,
            invalidQuestion,
            goToNextQuestion,
            lastQuestion,
        } = this.props;
        const { quizResults, currentQuestion, numberOfQuestions } = this.state;

        return (
            <div css={quizStyle}>
                {quiz.map((question, index) => {
                    const { id, answersChoice, answer } = question;

                    const isQuestionVisible = currentQuestion === index + 1;
                    const isQuestionLast = currentQuestion === numberOfQuestions;
                    const isQuestionAnswered = quizResults.some(
                        (result) => result.id === id,
                    );
                    let isQuestionValid = false;

                    if (
                        isQuestionAnswered && quizResults[index].selectedChoice === answer
                    ) {
                        isQuestionValid = true;
                    }

                    return (
                        <StyledQuestion isVisible={isQuestionVisible} key={id}>
                            <div
                                css={quizQuestionContentStyle}
                                data-sal="slide-up"
                                data-sal-duration="600"
                            >
                                <PageContentContainer align="left">
                                    {currentQuestion === 1 ? (
                                        <>
                                            <h2>Test yourself</h2>
                                            <p style={{ marginBottom: 30 }}>
                                                You seem interested in
                                                responsible investments. Want to
                                                do a quick test to find out how
                                                much you know?
                                            </p>
                                        </>
                                    ) : null}
                                    <Indicator
                                        currentQuestion={currentQuestion}
                                        numberOfQuestions={numberOfQuestions}
                                    />

                                    <h3>{question.question}</h3>

                                    {Object.entries(answersChoice).map(
                                        ([choiceKey, choiceValue]) => {
                                            let isChoiceValid = false;
                                            let isChoiceNotValid = false;

                                            if (isQuestionAnswered) {
                                                const questionResult = quizResults.find(
                                                    (result) => result.id === id,
                                                );
                                                const {
                                                    selectedChoice,
                                                } = questionResult;

                                                if (choiceKey === answer) {
                                                    isChoiceValid = true;
                                                } else if (
                                                    choiceKey === selectedChoice
                                                ) {
                                                    isChoiceNotValid = true;
                                                }
                                            }

                                            return (
                                                <AnswerChoice
                                                    key={choiceKey}
                                                    questionId={id}
                                                    labelKey={choiceKey}
                                                    labelValue={choiceValue}
                                                    isValid={isChoiceValid}
                                                    isNotValid={
                                                        isChoiceNotValid
                                                    }
                                                    isDisabled={
                                                        isQuestionAnswered
                                                    }
                                                    onClick={this.submitChoice}
                                                />
                                            );
                                        },
                                    )}
                                </PageContentContainer>
                            </div>

                            <div
                                css={quizQuestionVisuStyle}
                                data-sal="slide-left"
                                data-sal-duration="600"
                            >
                                <img
                                    src={imgSlide[currentQuestion - 1]}
                                    alt=""
                                />
                                {isQuestionAnswered && (
                                    <StyledQuestionResult
                                        isValid={isQuestionValid}
                                        className={`position-${[
                                            currentQuestion,
                                        ]}`}
                                    >
                                        <p>
                                            {isQuestionValid ? (
                                                <ValidIcon />
                                            ) : (
                                                <InvalidIcon />
                                            )}
                                            <strong>
                                                {isQuestionValid
                                                    ? validQuestion
                                                    : invalidQuestion}
                                            </strong>
                                            {isQuestionLast && (
                                                <p css={lastQuestionTextStyle}>
                                                    {lastQuestion}
                                                </p>
                                            )}
                                        </p>
                                        {!isQuestionLast && (
                                            <Button
                                                label={goToNextQuestion}
                                                onClick={this.goToNextQuestion}
                                                isInverse
                                            />
                                        )}
                                    </StyledQuestionResult>
                                )}
                            </div>
                        </StyledQuestion>
                    );
                })}
            </div>
        );
    }
}

Quiz.propTypes = {
    quiz: arrayOf(
        shape({
            id: string.isRequired,
            question: string.isRequired,
            answersChoice: shape.isRequired,
            answer: string.isRequired,
        }),
    ).isRequired,
    lastQuestion: string.isRequired,
    validQuestion: string.isRequired,
    invalidQuestion: string.isRequired,
    goToNextQuestion: string.isRequired,
};

export default Quiz;
