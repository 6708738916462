import React from 'react';

const LinkedinIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
        <path
            fill="#fff"
            d="M7.92,20.58H4.48V9.51H7.92ZM6.2,8a2,2,0,1,1,2-2A2,2,0,0,1,6.2,8ZM20.78,20.58H17.35V15.19c0-1.28,0-2.93-1.79-2.93s-2.06,1.4-2.06,2.84v5.48H10.07V9.51h3.3V11h0a3.63,3.63,0,0,1,3.26-1.79c3.48,0,4.11,2.29,4.11,5.27v6.08Z"
        />
    </svg>
);

export default LinkedinIcon;
