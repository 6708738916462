import React from 'react';

import Layout from '../components/layout/index';
import Header from '../components/header/index';
import PageScroller, {
    PageScrollerContext,
} from '../components/pageScroller/index';
import Page from '../components/pageScroller/page/index';
import PageContent from '../components/pageScroller/pageContent/index';
import PageContentContainer from '../components/pageScroller/pageContentContainer/index';
import PageVisu from '../components/pageScroller/pageVisu/index';
import IconList from '../components/iconList';
import Quiz from '../components/quiz/index';
import Button from '../components/button/index';
import ScrollToBottom from '../components/scrollToBottom/index';
import {
    stickyBtnWrapper,
    btnWrapper,
} from '../components/pageScroller/page/styles';

import ModuleIcon from '../icons/module';
import CaseStudyIcon from '../icons/caseStudy';
import CertificationIcon from '../icons/certification';

import imgSlide1 from '../images/slides/1.svg';
import imgSlide2 from '../images/slides/2.svg';
import imgSlide4 from '../images/slides/6.svg';
import plant from '../images/slides/plant.svg';

import { brandBeigeLight, brandBeigeDark } from '../styles/colors';

const quiz = [
    {
        id: 'question1',
        question: 'What does ESG mean?',
        answersChoice: {
            A: 'Ecological, socially equitable and government-assisted',
            B: 'Environmental, social and governance',
            C: 'Eco Sustainable Growth',
        },
        answer: 'B',
    },
    {
        id: 'question2',
        question: 'How many UN Sustainable Development Goals are there?',
        answersChoice: {
            A: '10',
            B: '12',
            C: '17',
            D: '24',
        },
        answer: 'C',
    },
    {
        id: 'question3',
        question: 'What is a Thematic approach to responsible investment?',
        answersChoice: {
            A:
                'Investing in companies which provide equipment and services to solve sustainability challenges, such as water scarcity',
            B:
                "Excluding certain companies from the potential investment universe because of the companies' business practices or their involvement in certain controversial activities, such as sale of tobacco products",
            C:
                'Investing in companies that have a responsible approach to business practices, products or services',
        },
        answer: 'A',
    },
];

const IndexPage = () => (
    <Layout>
        <div css={stickyBtnWrapper}>
            <Button
                label="Register for training"
                href="https://academy.candriam.com/register/"
                rel="noopener noreferrer"
                target="_blank"
            />
        </div>
        <PageScroller>
            {/* Need to put the Header in PageScroll, to get the PageScrollerContext */}
            <Header siteTitle="Candriam Academy" />

            <Page backgroundColor={brandBeigeLight} className="hero">
                <PageContent animFrom="up">
                    <PageContentContainer align="left">
                        <h1>It’s never too late to be part of the change</h1>
                        <p>
                            Get free training and certification to drive
                            sustainable investing for tomorrow.
                        </p>
                        <PageScrollerContext.Consumer>
                            {({ goToPage }) => (
                                <div css={btnWrapper}>
                                    <Button
                                        label="Register for training"
                                        onClick={() => goToPage(3)}
                                    />
                                </div>
                            )}
                        </PageScrollerContext.Consumer>
                    </PageContentContainer>
                </PageContent>

                <PageVisu animFrom="left">
                    <img src={imgSlide1} alt="" />
                </PageVisu>

                <ScrollToBottom label="Scroll" />
            </Page>

            <Page backgroundColor={brandBeigeDark}>
                <PageVisu animFrom="right">
                    <img src={imgSlide2} alt="" />
                </PageVisu>
                <PageContent animFrom="up">
                    <PageContentContainer>
                        <h2>
                            You can’t afford to ignore responsible investments
                        </h2>
                        <p>
                            Candriam has been at the forefront of responsible
                            investing for over two decades. This rapidly growing
                            segment of financial services is critical to
                            investors, business and society.
                        </p>
                        <p>
                            Jump on your chance to be part of it.
                            <br />
                            Get free training, open to all. Start today.
                        </p>
                    </PageContentContainer>
                </PageContent>

                <ScrollToBottom label="Scroll" />
            </Page>

            <Page backgroundColor={brandBeigeLight}>
                <PageContent size="fullwidth">
                    <Quiz
                        quiz={quiz}
                        validQuestion="Well done!"
                        invalidQuestion="Great try!"
                        goToNextQuestion="Next question!"
                        lastQuestion="You know a bit about responsible investing, so why not go further and get certification? Scroll and head to Candriam Academy."
                    />
                </PageContent>

                <ScrollToBottom label="Scroll to skip the quiz" />
            </Page>

            <Page backgroundColor={brandBeigeDark}>
                <PageVisu animFrom="right">
                    <img src={plant} alt="" className="greenies" />
                    <img src={imgSlide4} alt="" />
                    <IconList
                        list={[
                            {
                                icon: <ModuleIcon />,
                                label: 'Modules',
                            },
                            {
                                icon: <CaseStudyIcon />,
                                label: 'Case studies',
                            },
                            {
                                icon: <CertificationIcon />,
                                label: 'Certification',
                            },
                        ]}
                    />
                </PageVisu>

                <PageContent animFrom="up">
                    <PageContentContainer>
                        <h2>Join Candriam Academy</h2>
                        <strong>
                            The world’s first free training platform for
                            responsible investing
                        </strong>
                        <p>
                            Be inspired and informed about responsible investing
                            with convenient online training. Join 2500 Academy
                            Members who have worked through each module
                            (approximately 4 hours of training) to gain valuable
                            insights, skills, and real-life examples.
                        </p>
                        <p>
                            Take a positive step for your professional
                            development … and for the future generations.
                        </p>
                        <div css={btnWrapper}>
                            <Button
                                label="I want to register"
                                href="https://academy.candriam.com/register/"
                                rel="noopener noreferrer"
                                target="_blank"
                            />
                        </div>
                    </PageContentContainer>
                </PageContent>
            </Page>
        </PageScroller>
    </Layout>
);

export default IndexPage;
