const indicatorStyle = {
    right: 30,
    zIndex: 5,
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    justifyContent: 'center',
};

export default indicatorStyle;
