import { mq } from './mediaqueries';
import { greyDark, brandBlue } from './colors';

const global = {
    'html, body': {
        margin: 0,
        padding: 0,
        fontSize: 14,
        color: greyDark,
        fontFamily: 'Poppins',

        [mq[1]]: {
            fontSize: 15,
        },

        [mq[2]]: {
            fontSize: 16,
        },

        [mq[3]]: {
            fontSize: 18,
        },
    },

    h1: {
        fontSize: 24,
        marginTop: 0,
        color: brandBlue,
        lineHeight: '1.4em',

        [mq[1]]: {
            fontSize: 35,
            lineHeight: '42px',
        },
    },

    h2: {
        fontSize: 24,
        marginTop: 0,
        color: greyDark,
        marginBottom: 25,
        lineHeight: '1.4em',

        [mq[1]]: {
            fontSize: 35,
            lineHeight: '42px',
        },
    },

    h3: {
        fontSize: 18,
        color: brandBlue,
        marginTop: 0,
        marginBottom: 20,

        [mq[1]]: {
            fontSize: 18,
        },
    },

    h4: {
        fontSize: 16,
        marginTop: 0,
        color: greyDark,
        marginBottom: 20,

        [mq[1]]: {
            fontSize: 18,
        },

        [mq[2]]: {
            fontSize: 20,
        },

        [mq[3]]: {
            fontSize: 25,
        },
    },

    h5: {
        fontSize: 15,
        marginTop: 0,
        color: greyDark,

        [mq[1]]: {
            fontSize: 16,
        },

        [mq[2]]: {
            fontSize: 18,
        },

        [mq[3]]: {
            fontSize: 20,
        },
    },

    p: {
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 400,
        letterSpacing: 1,
        color: greyDark,

        [mq[3]]: {
            fontSize: 16,
        },
    },

    strong: {
        letterSpacing: '0.3px',
    },

    img: {
        maxWidth: '100%',
    },

    button: {
        fontSize: 12,
        fontFamily: 'Poppins',

        [mq[1]]: {
            fontSize: 13,
        },

        [mq[2]]: {
            fontSize: 14,
        },

        [mq[3]]: {
            fontSize: 15,
        },
    },
    // Disable animation on IE
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
        '[data-sal]': {
            opacity: '1 !important',
            transform: 'none !important',
        },
    },
};

export default global;
