import React from 'react';
import styled from '@emotion/styled';
import { node, string } from 'prop-types';

import pageVisuStyle from './styles';

const StyledPageVisu = styled.div(pageVisuStyle);

const PageVisu = ({ children, size, animFrom }) => (
    <StyledPageVisu
        size={size}
        data-sal={`slide-${animFrom}`}
        data-sal-duration="800"
    >
        {children}
    </StyledPageVisu>
);

PageVisu.propTypes = {
    children: node.isRequired,
    size: string,
    animFrom: string,
};

PageVisu.defaultProps = {
    size: null,
    animFrom: 'right',
};

export default PageVisu;
