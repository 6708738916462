import React from 'react';

const CertificationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
        <g fill="#0071ba">
            <path d="M44.68,53.79H10.06A2.75,2.75,0,0,1,7.32,51V3.72A2.74,2.74,0,0,1,10.06,1H44.68a2.75,2.75,0,0,1,2.75,2.74V51A2.75,2.75,0,0,1,44.68,53.79ZM10.07,3a.76.76,0,0,0-.76.76V51a.76.76,0,0,0,.76.76H44.68a.76.76,0,0,0,.76-.76V3.72A.76.76,0,0,0,44.68,3Z" />
            <path d="M38.59,11.42H14.11a1,1,0,0,1,0-2H38.59a1,1,0,1,1,0,2Z" />
            <path d="M32.52,18.49H14.11a1,1,0,0,1,0-2H32.52a1,1,0,1,1,0,2Z" />
            <path d="M37.12,37.83a3,3,0,0,0-2.9,2.4H40A3,3,0,0,0,37.12,37.83Z" />
            <path d="M37.13,45.71a4.94,4.94,0,0,1-4.9-4.32v7l5.2-2.48L42,48.35v-7A4.93,4.93,0,0,1,37.13,45.71Z" />
            <path d="M32.38,40.22h1.85a3,3,0,0,1,5.8,0h2v1.17c0-.2,0-.41,0-.62a4.94,4.94,0,0,0-9.87,0c0,.21,0,.41,0,.62V40.22Z" />
            <path d="M37.13,45.71A4.93,4.93,0,0,0,42,41.39V40.22H40a3.41,3.41,0,0,1,0,.55,3,3,0,1,1-5.9,0,2.57,2.57,0,0,1,.06-.55h-2v1.17A4.94,4.94,0,0,0,37.13,45.71Z" />
        </g>
    </svg>
);

export default CertificationIcon;
