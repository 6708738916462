import React from 'react';

const ModuleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
        <g fill="#0071ba">
            <path d="M37.22,22.17h-19a2.75,2.75,0,0,1-2.74-2.75V12.2a2.75,2.75,0,0,1,2.74-2.75h19A2.75,2.75,0,0,1,40,12.2v7.22A2.75,2.75,0,0,1,37.22,22.17Zm-19-10.73a.76.76,0,0,0-.76.76v7.22a.76.76,0,0,0,.76.76h19a.76.76,0,0,0,.76-.76V12.2a.76.76,0,0,0-.76-.76Z" />
            <path d="M45,53.8H10.39a2.74,2.74,0,0,1-2.74-2.74V3.74A2.74,2.74,0,0,1,10.39,1H45a2.74,2.74,0,0,1,2.74,2.74V51.06A2.74,2.74,0,0,1,45,53.8ZM10.39,3a.76.76,0,0,0-.76.76V51.06a.76.76,0,0,0,.76.76H45a.76.76,0,0,0,.76-.76V3.74A.76.76,0,0,0,45,3Z" />
            <path d="M31.87,39.07H30.28v3.29H27.53V39.07H22.4V36.69l4.75-10h3.13v10h1.59Zm-4.34-2.42V33.34l0-1.46.07-1.4h-.08a17.09,17.09,0,0,1-.82,2.12l-1.89,4Z" />
        </g>
    </svg>
);

export default ModuleIcon;
