import React from 'react';

const CaseStudyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
        <g fill="#0071ba">
            <g isolation="isolate">
                <path d="M25.39,43H22.51V33.76c0-1,0-2.15.15-3.46-.05.07-.23.29-.55.66s-.94,1-1.87,2l-1.63-1.78,4.18-3.87h2.6Z" />
                <path d="M37.77,35.12c0,2.85-.34,4.9-1,6.17a3.46,3.46,0,0,1-3.3,1.9,3.41,3.41,0,0,1-3.27-1.91,14.15,14.15,0,0,1-1-6.16q0-4.24,1-6.17A3.46,3.46,0,0,1,33.48,27a3.46,3.46,0,0,1,3.28,1.88Q37.77,30.79,37.77,35.12Zm-5.67,0a22.43,22.43,0,0,0,.28,4.45q.28,1.14,1.08,1.14a.9.9,0,0,0,.83-.49,4.36,4.36,0,0,0,.43-1.64c.08-.76.13-1.91.13-3.46a21.27,21.27,0,0,0-.3-4.42c-.21-.79-.57-1.18-1.09-1.18s-.89.39-1.08,1.17A22.6,22.6,0,0,0,32.1,35.12Z" />
            </g>
            <path d="M45,53.79H10.4A2.75,2.75,0,0,1,7.66,51V3.72A2.74,2.74,0,0,1,10.4,1H45a2.74,2.74,0,0,1,2.74,2.74V51A2.75,2.75,0,0,1,45,53.79ZM10.4,3a.76.76,0,0,0-.76.76V51a.76.76,0,0,0,.76.76H45a.76.76,0,0,0,.76-.76V3.72A.76.76,0,0,0,45,3Z" />
            <path d="M14.3,2V18.23l4.84-3,4.25,3V2h-9.3" />
        </g>
    </svg>
);

export default CaseStudyIcon;
