import React from 'react';

const ValidIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="45px" viewBox="0 0 50 45">
        <path d="M19.56,45L1.28,29.26c-1.52-1.31-1.71-3.62-0.42-5.17c1.29-1.54,3.58-1.73,5.1-0.42l12.5,10.76l25.06-33c1.22-1.6,3.49-1.9,5.07-0.67c1.58,1.23,1.88,3.54,0.66,5.14L19.56,45z" />
    </svg>

);

export default ValidIcon;
