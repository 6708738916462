import React from 'react';
import { func, number, bool } from 'prop-types';
import styled from '@emotion/styled';

import getIndicatorButtonStyle from './styles';

const StyledButton = styled.button(getIndicatorButtonStyle);

const IndicatorButton = ({ active, pageIndex, goToPage }) => (
    <StyledButton
        type="button"
        active={active}
        aria-label="pageScroller - indicator"
        onClick={() => goToPage(pageIndex)}
    />
);

IndicatorButton.propTypes = {
    active: bool.isRequired,
    goToPage: func.isRequired,
    pageIndex: number.isRequired,
};

export default IndicatorButton;
