import React from 'react';
import styled from '@emotion/styled';
import { node, string } from 'prop-types';

import { getPageStyle, pageContentStyle } from './styles';

const StyledPage = styled.div(getPageStyle);
// eslint-disable-next-line
const Page = ({ children, backgroundColor, backgroundImg, className }) => (
    <StyledPage
        backgroundColor={backgroundColor}
        backgroundImg={backgroundImg}
        data-page
        className={className}
    >
        <div css={pageContentStyle}>{children}</div>
    </StyledPage>
);

Page.propTypes = {
    children: node.isRequired,
    backgroundColor: string,
    backgroundImg: string,
    className: string,
};

Page.defaultProps = {
    backgroundColor: 'transparent',
    backgroundImg: null,
    className: null,
};

export default Page;
