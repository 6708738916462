import { mq, mqMinHeight } from '../../styles/mediaqueries';
import { brandBlue } from '../../styles/colors';

const scrollToBottomStyle = {
    left: 0,
    bottom: 0,
    border: 0,
    fontSize: 11,
    width: '100%',
    display: 'none',
    fontWeight: 700,
    margin: '0 auto',
    paddingBottom: 15,
    alignItems: 'center',
    position: 'absolute',
    flexDirection: 'column',
    color: brandBlue,
    textTransform: 'uppercase',

    [mq[1]]: {
        fontSize: 12,
        display: 'flex',
    },

    [mq[2]]: {
        fontSize: 13,
    },

    [mq[3]]: {
        fontSize: 14,
    },

    [mqMinHeight]: {
        display: 'none',
    },

    svg: {
        width: 20,
        height: 20,
        marginTop: 5,
        fill: brandBlue,
    },
};

export default scrollToBottomStyle;
